import { getHubspotCookie } from './features/getHubspotCookie';

interface HubspotFormField {
  name: string;
  value: string;
}

(() => {
  // get hubspot forms
  const hubspotForms: NodeListOf<HTMLFormElement> = document.querySelectorAll('[data-hs-form-id]');

  hubspotForms.forEach(form => {
    form.parentElement?.classList.remove('w-form'); // Webflow form class

    const formId: string | null = form.getAttribute('data-hs-form-id');
    const portalId: string | null = form.getAttribute('data-hs-portal-id');
    const redirectUrl: string | null = form.getAttribute('data-redirect-url');
    const redirectTimeout: number = parseInt(form.getAttribute('data-redirect-timeout') || '6000', 10);

    if (!formId || !portalId) {
      console.error('Hubspot form id or portal id is missing - please check the HTML attributes on the form');
      return;
    }

    // add submit event
    form.addEventListener('submit', e => {
      e.preventDefault();

      const fieldElements: NodeListOf<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> =
        form.querySelectorAll('input, textarea, select');
      const fields: HubspotFormField[] = [];

      fieldElements.forEach(fieldElement => {
        const name = fieldElement.getAttribute('name');
        const value = fieldElement.value;

        if (!name) {
          console.error('Form field name is missing - please check the HTML attributes on the form fields');
          return;
        }

        fields.push({ name, value });
      });

      const payload = {
        fields,
        context: {
          hutk: getHubspotCookie(),
          pageUri: window.location.href,
          pageName: document.title,
        },
      };

      fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (response.ok) {
            form.style.display = 'none';

            const formSuccess: HTMLDivElement | null | undefined =
              form.parentElement?.querySelector('[data-form-success]');
            if (formSuccess) {
              formSuccess.style.display = 'block';
            }

            if (redirectUrl) {
              setTimeout(() => {
                window.location.assign(redirectUrl);
              }, redirectTimeout);
            }
          }
        })
        .catch(error => {
          console.error('Form submission failed > ', error);
        });
    });
  });
})();
